import React from 'react'

const Balloons = () => {
  return (
    <svg viewBox="0 0 310 510" version="1.0">
      <defs id="defs4">
        <linearGradient id="linearGradient22425">
          <stop id="stop22427" stopColor="#f2f2f2" offset="0" />
          <stop id="stop22433" stopColor="#c3c3c3" offset=".5" />
          <stop id="stop22435" stopColor="#bdbdb5" offset=".5" />
          <stop id="stop22437" stopColor="#f2f2f2" offset=".875" />
          <stop id="stop22429" stopColor="#eeeeec" offset="1" />
        </linearGradient>
        <linearGradient id="linearGradient21422">
          <stop id="stop21424" stopColor="#f19900" offset="0" />
          <stop id="stop21426" stopColor="#ddb100" stopOpacity="0" offset="1" />
        </linearGradient>
        <linearGradient id="linearGradient20361">
          <stop id="stop20363" stopColor="#40f100" offset="0" />
          <stop id="stop20365" stopColor="#3bdd00" stopOpacity="0" offset="1" />
        </linearGradient>
        <linearGradient id="linearGradient19337">
          <stop id="stop19339" stopColor="#00a1f1" offset="0" />
          <stop id="stop19341" stopColor="#0085dd" stopOpacity="0" offset="1" />
        </linearGradient>
        <linearGradient id="linearGradient15393">
          <stop id="stop15395" stopColor="#f10000" offset="0" />
          <stop id="stop15397" stopColor="#d00" stopOpacity="0" offset="1" />
        </linearGradient>
        <linearGradient id="linearGradient7586">
          <stop id="stop7588" stopColor="#fff" stopOpacity="0" offset="0" />
          <stop id="stop7590" stopColor="#f77" offset="1" />
        </linearGradient>
        <linearGradient id="linearGradient4655">
          <stop id="stop4657" stopColor="#fff" offset="0" />
          <stop id="stop4659" stopColor="#fff" stopOpacity="0" offset="1" />
        </linearGradient>
        <radialGradient
          id="radialGradient3660"
          href="#linearGradient7586"
          gradientUnits="userSpaceOnUse"
          cy="295.22"
          cx="238"
          gradientTransform="matrix(1 0 0 1.336 117 135.79)"
          r="81"
        />
        <radialGradient
          id="radialGradient3662"
          gradientUnits="userSpaceOnUse"
          cy="295.22"
          cx="238"
          gradientTransform="matrix(1.204 -8.9416e-8 1.3423e-7 1.8074 68.442 -3.3648)"
          r="81"
        >
          <stop id="stop20351" stopColor="#71ff89" stopOpacity="0" offset="0" />
          <stop id="stop20353" stopColor="#07dd00" offset="1" />
        </radialGradient>
        <radialGradient
          id="radialGradient3664"
          href="#linearGradient4655"
          gradientUnits="userSpaceOnUse"
          cy="221.08"
          cx="157"
          gradientTransform="matrix(2.2027e-7 2.9354 -1.1157 8.3723e-8 520.66 -38.859)"
          r="72.859"
        />
        <radialGradient
          id="radialGradient3666"
          gradientUnits="userSpaceOnUse"
          cy="357.45"
          cx="319"
          gradientTransform="matrix(.96916 -1.6344 1.5736 .93311 -435.67 826.28)"
          r="55.438"
        >
          <stop id="stop20357" stopColor="#08f100" offset="0" />
          <stop id="stop20359" stopColor="#6edd00" stopOpacity="0" offset="1" />
        </radialGradient>
        <radialGradient
          id="radialGradient3668"
          href="#linearGradient4655"
          gradientUnits="userSpaceOnUse"
          cy="222.73"
          cx="230.36"
          gradientTransform="matrix(1.7549 .019282 -.0076637 1.0097 -67.188 220.96)"
          r="62.046"
        />
        <linearGradient
          id="linearGradient3670"
          x1="243.46"
          href="#linearGradient20361"
          gradientUnits="userSpaceOnUse"
          y1="403.14"
          gradientTransform="translate(117,235)"
          x2="243.46"
          y2="395.82"
        />
        <linearGradient
          id="linearGradient3672"
          x1="236.42"
          href="#linearGradient20361"
          gradientUnits="userSpaceOnUse"
          y1="400.61"
          gradientTransform="translate(117,235)"
          x2="231.55"
          y2="396.46"
        />
        <linearGradient
          id="linearGradient3674"
          x1="285.79"
          href="#linearGradient22425"
          spreadMethod="reflect"
          gradientUnits="userSpaceOnUse"
          y1="634.36"
          x2="271.79"
          y2="566.74"
        />
        <radialGradient
          id="radialGradient3676"
          href="#linearGradient7586"
          gradientUnits="userSpaceOnUse"
          cy="295.22"
          cx="238"
          gradientTransform="matrix(1 0 0 1.336 220 -102.21)"
          r="81"
        />
        <radialGradient
          id="radialGradient3678"
          gradientUnits="userSpaceOnUse"
          cy="295.22"
          cx="238"
          gradientTransform="matrix(1.204 -8.9416e-8 1.3423e-7 1.8074 171.44 -241.36)"
          r="81"
        >
          <stop id="stop18358" stopColor="#71d9ff" stopOpacity="0" offset="0" />
          <stop id="stop18360" stopColor="#0093dd" offset="1" />
        </radialGradient>
        <radialGradient
          id="radialGradient3680"
          href="#linearGradient4655"
          gradientUnits="userSpaceOnUse"
          cy="221.08"
          cx="157"
          gradientTransform="matrix(2.2027e-7 2.9354 -1.1157 8.3723e-8 623.66 -276.86)"
          r="72.859"
        />
        <radialGradient
          id="radialGradient3682"
          gradientUnits="userSpaceOnUse"
          cy="357.45"
          cx="319"
          gradientTransform="matrix(.96916 -1.6344 1.5736 .93311 -332.67 588.28)"
          r="55.438"
        >
          <stop id="stop18364" stopColor="#00d1f1" offset="0" />
          <stop id="stop18366" stopColor="#0058dd" stopOpacity="0" offset="1" />
        </radialGradient>
        <radialGradient
          id="radialGradient3684"
          href="#linearGradient4655"
          gradientUnits="userSpaceOnUse"
          cy="222.73"
          cx="230.36"
          gradientTransform="matrix(1.7549 .019282 -.0076637 1.0097 35.812 -17.044)"
          r="62.046"
        />
        <linearGradient
          id="linearGradient3686"
          x1="243.46"
          href="#linearGradient19337"
          gradientUnits="userSpaceOnUse"
          y1="403.14"
          gradientTransform="translate(220,-3)"
          x2="243.46"
          y2="395.82"
        />
        <linearGradient
          id="linearGradient3688"
          x1="236.42"
          href="#linearGradient19337"
          gradientUnits="userSpaceOnUse"
          y1="400.61"
          gradientTransform="translate(220,-3)"
          x2="231.55"
          y2="396.46"
        />
        <linearGradient
          id="linearGradient3690"
          x1="279.92"
          href="#linearGradient22425"
          spreadMethod="reflect"
          gradientUnits="userSpaceOnUse"
          y1="599.63"
          x2="262.92"
          y2="530.63"
        />
        <radialGradient
          id="radialGradient3692"
          href="#linearGradient7586"
          gradientUnits="userSpaceOnUse"
          cy="295.22"
          cx="238"
          gradientTransform="matrix(1 0 0 1.336 0 -99.207)"
          r="81"
        />
        <radialGradient
          id="radialGradient3694"
          gradientUnits="userSpaceOnUse"
          cy="295.22"
          cx="238"
          gradientTransform="matrix(1.204 -3.9537e-8 5.9582e-8 1.8145 -48.558 -240.44)"
          r="81"
        >
          <stop id="stop3653" stopColor="#ff7171" stopOpacity="0" offset="0" />
          <stop id="stop3655" stopColor="#d00" offset="1" />
        </radialGradient>
        <radialGradient
          id="radialGradient3696"
          href="#linearGradient4655"
          gradientUnits="userSpaceOnUse"
          cy="221.08"
          cx="157"
          gradientTransform="matrix(3.1293e-7 2.9354 -1.147 1.2228e-7 410.58 -273.86)"
          r="72.859"
        />
        <radialGradient
          id="radialGradient3698"
          href="#linearGradient15393"
          gradientUnits="userSpaceOnUse"
          cy="357.45"
          cx="319"
          gradientTransform="matrix(1 -1.655 1.5658 .94614 -559.72 593.18)"
          r="55.438"
        />
        <radialGradient
          id="radialGradient3700"
          href="#linearGradient4655"
          gradientUnits="userSpaceOnUse"
          cy="222.73"
          cx="230.36"
          gradientTransform="matrix(1.7549 .019282 -.0076637 1.0097 -184.19 -14.044)"
          r="62.046"
        />
        <linearGradient
          id="linearGradient3702"
          x1="243.46"
          href="#linearGradient15393"
          gradientUnits="userSpaceOnUse"
          x2="243.46"
          y1="403.14"
          y2="395.82"
        />
        <linearGradient
          id="linearGradient3704"
          x1="236.42"
          href="#linearGradient15393"
          gradientUnits="userSpaceOnUse"
          x2="231.55"
          y1="400.61"
          y2="396.46"
        />
        <linearGradient
          id="linearGradient3706"
          x1="207.78"
          href="#linearGradient22425"
          spreadMethod="reflect"
          gradientUnits="userSpaceOnUse"
          y1="631.18"
          x2="226.01"
          y2="585.34"
        />
        <radialGradient
          id="radialGradient3708"
          href="#linearGradient7586"
          gradientUnits="userSpaceOnUse"
          cy="295.22"
          cx="238"
          gradientTransform="matrix(1 0 0 1.336 184 145.79)"
          r="81"
        />
        <radialGradient
          id="radialGradient3710"
          gradientUnits="userSpaceOnUse"
          cy="295.22"
          cx="238"
          gradientTransform="matrix(1.204 -8.9416e-8 1.3423e-7 1.8074 135.44 6.6352)"
          r="81"
        >
          <stop id="stop21412" stopColor="#ffe371" stopOpacity="0" offset="0" />
          <stop id="stop21414" stopColor="#dda200" offset="1" />
        </radialGradient>
        <radialGradient
          id="radialGradient3712"
          href="#linearGradient4655"
          gradientUnits="userSpaceOnUse"
          cy="221.08"
          cx="157"
          gradientTransform="matrix(2.2027e-7 2.9354 -1.1157 8.3723e-8 587.66 -28.859)"
          r="72.859"
        />
        <radialGradient
          id="radialGradient3714"
          gradientUnits="userSpaceOnUse"
          cy="357.45"
          cx="319"
          gradientTransform="matrix(.96916 -1.6344 1.5736 .93311 -368.67 836.28)"
          r="55.438"
        >
          <stop id="stop21418" stopColor="#f1b900" offset="0" />
          <stop id="stop21420" stopColor="#ddb800" stopOpacity="0" offset="1" />
        </radialGradient>
        <radialGradient
          id="radialGradient3716"
          href="#linearGradient4655"
          gradientUnits="userSpaceOnUse"
          cy="222.73"
          cx="230.36"
          gradientTransform="matrix(1.7549 .019282 -.0076637 1.0097 -.18751 230.96)"
          r="62.046"
        />
        <linearGradient
          id="linearGradient3718"
          x1="243.46"
          href="#linearGradient21422"
          gradientUnits="userSpaceOnUse"
          y1="403.14"
          gradientTransform="translate(184,245)"
          x2="243.46"
          y2="395.82"
        />
        <linearGradient
          id="linearGradient3720"
          x1="236.42"
          href="#linearGradient21422"
          gradientUnits="userSpaceOnUse"
          y1="400.61"
          gradientTransform="translate(184,245)"
          x2="231.55"
          y2="396.46"
        />
        <linearGradient
          id="linearGradient3722"
          x1="279.74"
          href="#linearGradient22425"
          spreadMethod="reflect"
          gradientUnits="userSpaceOnUse"
          y1="531.27"
          x2="279.74"
          y2="485.27"
        />
      </defs>
      <g id="layer1" transform="translate(-106.58 -130.18)">
        <g id="g3527" transform="translate(5.3359 2.509)">
          <g
            id="g20367"
            transform="matrix(.99865 .31609 -.31937 .98838 126.36 -373.43)"
          >
            <path
              id="path19352"
              fill="url(#radialGradient3660)"
              d="m355 422c-44.712 0-81 26.592-81 84 0 55.049 48.062 118.53 77.344 123.66-1.0394 0.72957-2.4261 1.0311-3.5938 3.9375-1.8682 4.6503 4.7837 0.99671 4.875 0.0937 0.19427-1.9208 3.3716 1.6031 4.5312 3.625s3.1154 0.82531 3.4375 0.21875c0.32211-0.60654-0.0368-3.7044-0.78125-5.375-0.78224-1.7553-1.2163-1.2879-2.1562-2.3438 29.15-3.84 78.34-68.15 78.34-123.82 0-57.408-36.288-84-81-84z"
            />
            <path
              id="path19354"
              fill="url(#radialGradient3662)"
              d="m355 422c-44.712 0-81 26.592-81 84 0 55.049 48.062 118.53 77.344 123.66-1.0394 0.72957-2.4261 1.0311-3.5938 3.9375-1.8682 4.6503 4.7837 0.99671 4.875 0.0937 0.19427-1.9208 3.3716 1.6031 4.5312 3.625s3.1154 0.82531 3.4375 0.21875c0.32211-0.60654-0.0368-3.7044-0.78125-5.375-0.78224-1.7553-1.2163-1.2879-2.1562-2.3438 29.15-3.84 78.34-68.15 78.34-123.82 0-57.408-36.288-84-81-84z"
            />
            <path
              id="path19356"
              opacity=".5"
              fill="url(#radialGradient3664)"
              d="m355 422c-44.712 0-81 26.592-81 84 0 47.131 35.239 100.42 63.75 118-22.293-24.559-42.656-63.918-42.656-99.75 0-57.408 36.288-84 81-84 16.071 0 31.024 3.464 43.625 10.531-14.79-19.48-38.28-28.78-64.72-28.78zm-7.3438 211.84c-0.78171 2.2084 0.56435 2.2899 2.0625 1.75-0.68855-0.57475-1.3719-1.1415-2.0625-1.75z"
            />
            <path
              id="path19358"
              opacity=".5"
              fill="url(#radialGradient3666)"
              d="m403.72 435.59c14.33 13.828 23.156 34.665 23.156 63 0 55.675-49.185 119.98-78.344 123.81-3.6657 1.9589-3.7735 2.8461-2.9912 4.6014 0.74447 1.6706 6.2508 2.5108 5.9287 3.1173-0.21286 0.40083-1.124 1.0579-2.0625 0.875-0.56053 0.52918-1.1328 1.2907-1.6562 2.5938-1.8682 4.6503 4.7837 0.99671 4.875 0.0937 0.19427-1.9208 3.3716 1.6031 4.5312 3.625s3.1154 0.82531 3.4375 0.21875c0.32211-0.60654-0.0368-3.7044-0.78125-5.375-0.78224-1.7553-1.2163-1.2879-2.1562-2.3438 29.15-3.81 78.34-68.12 78.34-123.79 0-33.944-12.668-57.136-32.281-70.406z"
            />
            <path
              id="path19360"
              opacity=".9"
              fill="url(#radialGradient3668)"
              d="m426.44 483.93c0 32.243-31.53 58.411-70.379 58.411s-70.379-26.168-70.379-58.411 31.53-58.411 70.379-58.411 70.379 26.168 70.379 58.411z"
            />
            <path
              id="path19362"
              fillRule="evenodd"
              fill="url(#linearGradient3670)"
              d="m357.85 630.82s1.0837 0.45153 1.5352 1.6255c0.45154 1.174 1.3546 4.2444 0.99338 4.7863-0.36123 0.54184-0.90307 0.81276-1.3546 0.90306-0.45154 0.0903-0.36123-0.81276-0.0903-1.8964 0.27092-1.0837 0.36123-0.99337 0-2.6189s-0.90307-2.7092-1.0837-2.7995z"
            />
            <path
              id="path19364"
              fillRule="evenodd"
              fill="url(#linearGradient3672)"
              d="m348.55 635.6s2.8898-0.99337 3.251-1.5352c0.36123-0.54184 0.45153-1.2643 0.90307-1.4449 0.45153-0.18062 0.72245-0.81276 0.72245-0.81276l-1.6255-0.0903s0-0.99338-1.4449 0.90307-1.7158 3.0704-1.8061 2.9801z"
            />
          </g>
          <path
            id="path22445"
            fill="url(#linearGradient3674)"
            d="m276.53 363c-0.56743 8.5219 6.0189 10.012 1.5013 0.375-17.182-9.7727 8.876 0.42639 7.7149-0.0625-7.4588-3.1408-7.3692-2.3371-12.749-1.0625-9.2637 10.421-5.6939 26.137-6.1838 39.113-1.8545 18.116 2.0959 35.954 2.3981 54.008 2.5025 16.309-6.2312 28.752-13.2 42.483-9.1694 14.811-11.564 29.052-8.8634 45.984 1.7049 17.977 7.2227 35.222 10.559 52.899 2.3154 12.391 3.01 25.04 2.9388 37.627h-2.7301c0.29702-12.589 0.0888-25.286-2.7177-37.627-2.7058-17.801-7.4986-35.138-10.307-52.899-2.8916-16.975-0.15364-30.903 8.478-45.984 6.9939-13.759 15.71-26.134 14.241-42.483-1.2442-17.998-3.8812-36.006-3.4469-54.008 0.70922-12.956-3.3193-28.555 5.6267-39.113 1.7998-0.45502 15.695 0.55205 12.642 1.0625-2.5288 0.42273-11.062-6.0324-0.84757 0.0625 8.6841 16.347-23.045 0.97122-1.1237-0.375h-3.931z"
          />
        </g>
        <g id="g3494" transform="translate(5.3359 2.509)">
          <g
            id="g20418"
            transform="matrix(1.0294 -.054304 .054466 1.0263 -247.84 -33.638)"
          >
            <path
              id="path17359"
              fill="url(#radialGradient3676)"
              d="m458 184c-44.712 0-81 26.592-81 84 0 55.049 48.062 118.53 77.344 123.66-1.0394 0.72957-2.4261 1.0311-3.5938 3.9375-1.8682 4.6503 4.7837 0.99671 4.875 0.0937 0.19427-1.9208 3.3716 1.6031 4.5312 3.625s3.1154 0.82531 3.4375 0.21875c0.32211-0.60654-0.0368-3.7044-0.78125-5.375-0.78224-1.7553-1.2163-1.2879-2.1562-2.3438 29.15-3.84 78.34-68.15 78.34-123.82 0-57.408-36.288-84-81-84z"
            />
            <path
              id="path17361"
              fill="url(#radialGradient3678)"
              d="m458 184c-44.712 0-81 26.592-81 84 0 55.049 48.062 118.53 77.344 123.66-1.0394 0.72957-2.4261 1.0311-3.5938 3.9375-1.8682 4.6503 4.7837 0.99671 4.875 0.0937 0.19427-1.9208 3.3716 1.6031 4.5312 3.625s3.1154 0.82531 3.4375 0.21875c0.32211-0.60654-0.0368-3.7044-0.78125-5.375-0.78224-1.7553-1.2163-1.2879-2.1562-2.3438 29.15-3.84 78.34-68.15 78.34-123.82 0-57.408-36.288-84-81-84z"
            />
            <path
              id="path17363"
              opacity=".5"
              fill="url(#radialGradient3680)"
              d="m458 184c-44.712 0-81 26.592-81 84 0 47.131 35.239 100.42 63.75 118-22.293-24.559-42.656-63.918-42.656-99.75 0-57.408 36.288-84 81-84 16.071 0 31.024 3.464 43.625 10.531-14.79-19.48-38.28-28.78-64.72-28.78zm-7.3438 211.84c-0.78171 2.2084 0.56435 2.2899 2.0625 1.75-0.68855-0.57475-1.3719-1.1415-2.0625-1.75z"
            />
            <path
              id="path17365"
              opacity=".5"
              fill="url(#radialGradient3682)"
              d="m506.72 197.59c14.33 13.828 23.156 34.665 23.156 63 0 55.675-49.185 119.98-78.344 123.81-3.6657 1.9589-3.7735 2.8461-2.9912 4.6014 0.74447 1.6706 6.2508 2.5108 5.9287 3.1173-0.21286 0.40083-1.124 1.0579-2.0625 0.875-0.56053 0.52918-1.1328 1.2907-1.6562 2.5938-1.8682 4.6503 4.7837 0.99671 4.875 0.0937 0.19427-1.9208 3.3716 1.6031 4.5312 3.625s3.1154 0.82531 3.4375 0.21875c0.32211-0.60654-0.0368-3.7044-0.78125-5.375-0.78224-1.7553-1.2163-1.2879-2.1562-2.3438 29.15-3.81 78.34-68.12 78.34-123.79 0-33.944-12.668-57.136-32.281-70.406z"
            />
            <path
              id="path17367"
              opacity=".9"
              fill="url(#radialGradient3684)"
              d="m529.44 245.93c0 32.243-31.53 58.411-70.379 58.411s-70.379-26.168-70.379-58.411 31.53-58.411 70.379-58.411 70.379 26.168 70.379 58.411z"
            />
            <path
              id="path17369"
              fillRule="evenodd"
              fill="url(#linearGradient3686)"
              d="m460.85 392.82s1.0837 0.45153 1.5352 1.6255c0.45154 1.174 1.3546 4.2444 0.99338 4.7863-0.36123 0.54184-0.90307 0.81276-1.3546 0.90306-0.45154 0.0903-0.36123-0.81276-0.0903-1.8964 0.27092-1.0837 0.36123-0.99337 0-2.6189s-0.90307-2.7092-1.0837-2.7995z"
            />
            <path
              id="path17371"
              fillRule="evenodd"
              fill="url(#linearGradient3688)"
              d="m451.55 397.6s2.8898-0.99337 3.251-1.5352c0.36123-0.54184 0.45153-1.2643 0.90307-1.4449 0.45153-0.18062 0.72245-0.81276 0.72245-0.81276l-1.6255-0.0903s0-0.99338-1.4449 0.90307-1.7158 3.0704-1.8061 2.9801z"
            />
          </g>
          <path
            id="path22439"
            fill="url(#linearGradient3690)"
            d="m249 342c-10.401-0.56964-17.423 8.4508-4.0268 9 10.009-1.4242-4.1866-8.1789-3.4226-6.7188 2.597 4.9633 10.788 11.636 14.347 16.883 7.0315 15.158-8.7348 23.773-13.447 36.999-6.5281 16.522 3.2359 24.919 13.063 36.491 10.578 11.06 30.483 15.563 22.613 34-9.368 10.888-13.744 23.992-17.012 37.68-5.3235 15.315-6.2303 31.088-6.4515 47.141-2.6716 20.77-0.83194 41.631 0.73552 62.38 1.856 11.707 0.95733 5.8984 2.6983 17.425h-2.1074c-1.613-11.521-0.81959-5.7126-2.3785-17.425-3.161-20.821 0.0441-41.433-0.88712-62.38-0.0821-16.008 0.54603-31.756 5.5267-47.141 3.4941-13.415 8.0938-26.671 16.837-37.68 9.0213-18.164-11.212-23.309-21.993-34-10.529-11.278-19.462-19.954-13.242-36.491 4.7468-13.101 20.123-21.825 13.329-36.999-12.841-19.687-1.3542-19.223-4.9035-10.164-15.591 1.6323-19.332-6.3894-3.2768-9h3.9983z"
          />
        </g>
        <g id="g3505" transform="translate(-.13979 -.22884)">
          <g
            id="g19343"
            transform="matrix(.98421 -.17701 .17701 .98421 -88.499 14.791)"
          >
            <path
              id="path4663"
              fill="url(#radialGradient3692)"
              d="m238 187c-44.712 0-81 26.592-81 84 0 55.049 48.062 118.53 77.344 123.66-1.0394 0.72957-2.4261 1.0311-3.5938 3.9375-1.8682 4.6503 4.7837 0.99671 4.875 0.0937 0.19427-1.9208 3.3716 1.6031 4.5312 3.625s3.1154 0.82531 3.4375 0.21875c0.32211-0.60654-0.0368-3.7044-0.78125-5.375-0.78224-1.7553-1.2163-1.2879-2.1562-2.3438 29.15-3.84 78.34-68.15 78.34-123.82 0-57.408-36.288-84-81-84z"
            />
            <path
              id="path2676"
              fill="url(#radialGradient3694)"
              d="m238 187c-44.712 0-81 26.592-81 84 0 55.049 48.062 118.53 77.344 123.66-1.0394 0.72957-2.4261 1.0311-3.5938 3.9375-1.8682 4.6503 4.7837 0.99671 4.875 0.0937 0.19427-1.9208 3.3716 1.6031 4.5312 3.625s3.1154 0.82531 3.4375 0.21875c0.32211-0.60654-0.0368-3.7044-0.78125-5.375-0.78224-1.7553-1.2163-1.2879-2.1562-2.3438 29.15-3.84 78.34-68.15 78.34-123.82 0-57.408-36.288-84-81-84z"
            />
            <path
              id="path12462"
              opacity=".5"
              fill="url(#radialGradient3696)"
              d="m238 187c-44.712 0-81 26.592-81 84 0 47.131 35.239 100.42 63.75 118-22.293-24.559-42.656-63.918-42.656-99.75 0-57.408 36.288-84 81-84 16.071 0 31.024 3.464 43.625 10.531-14.79-19.48-38.28-28.78-64.72-28.78zm-7.3438 211.84c-0.78171 2.2084 0.56435 2.2899 2.0625 1.75-0.68855-0.57475-1.3719-1.1415-2.0625-1.75z"
            />
            <path
              id="path14413"
              opacity=".5"
              fill="url(#radialGradient3698)"
              d="m286.72 200.59c14.33 13.828 23.156 34.665 23.156 63 0 55.675-49.185 119.98-78.344 123.81-3.6657 1.9589-3.7735 2.8461-2.9912 4.6014 0.74447 1.6706 6.2508 2.5108 5.9287 3.1173-0.21286 0.40083-1.124 1.0579-2.0625 0.875-0.56053 0.52918-1.1328 1.2907-1.6562 2.5938-1.8682 4.6503 4.7837 0.99671 4.875 0.0937 0.19427-1.9208 3.3716 1.6031 4.5312 3.625s3.1154 0.82531 3.4375 0.21875c0.32211-0.60654-0.0368-3.7044-0.78125-5.375-0.78224-1.7553-1.2163-1.2879-2.1562-2.3438 29.15-3.81 78.34-68.12 78.34-123.79 0-33.944-12.668-57.136-32.281-70.406z"
            />
            <path
              id="path11482"
              opacity=".9"
              fill="url(#radialGradient3700)"
              d="m309.44 248.93c0 32.243-31.53 58.411-70.379 58.411s-70.379-26.168-70.379-58.411 31.53-58.411 70.379-58.411 70.379 26.168 70.379 58.411z"
            />
            <path
              id="path15401"
              fillRule="evenodd"
              fill="url(#linearGradient3702)"
              d="m240.85 395.82s1.0837 0.45153 1.5352 1.6255c0.45154 1.174 1.3546 4.2444 0.99338 4.7863-0.36123 0.54184-0.90307 0.81276-1.3546 0.90306-0.45154 0.0903-0.36123-0.81276-0.0903-1.8964 0.27092-1.0837 0.36123-0.99337 0-2.6189s-0.90307-2.7092-1.0837-2.7995z"
            />
            <path
              id="path16380"
              fillRule="evenodd"
              fill="url(#linearGradient3704)"
              d="m231.55 400.6s2.8898-0.99337 3.251-1.5352c0.36123-0.54184 0.45153-1.2643 0.90307-1.4449 0.45153-0.18062 0.72245-0.81276 0.72245-0.81276l-1.6255-0.0903s0-0.99338-1.4449 0.90307-1.7158 3.0704-1.8061 2.9801z"
            />
          </g>
          <path
            id="path22441"
            fill="url(#linearGradient3706)"
            d="m215.41 361.5c-16.147 3.843 16.519 8.7913-2.1455 2.125-4.7449 10.237-1.1076 26.112 1.8603 36.93 6.0431 13.683 21.1 17.838 24.897 32.529-2.2956 14.177-6.4792 26.667-4.8415 41.235 2.8518 16.256 10.966 30.483 18.504 44.882 5.0752 16.29-1.2935 32.553-2.292 49.043-0.0428 14.378-1.4859 28.818 0.56988 43.08 0.57478 7.0451 3.4487 13.396 6.046 19.851h-2.7833c-2.5808-6.4442-5.2015-12.798-5.5471-19.851-2.5126-14.24-1.4237-28.699-0.47443-43.08 1.1964-16.345 7.0625-32.978 2.1234-49.043-7.3491-14.592-15.386-28.574-18.66-44.882-2.1322-14.614 2.6443-26.99 4.573-41.235-3.529-14.607-18.721-18.834-24.583-32.529-2.7432-10.604-7.8012-27.243-2.671-36.93 31.787-2.1341-13.01 8.9935 1.6045-2.125h3.8195z"
          />
        </g>
        <g id="g3516" transform="translate(5.3359 5.2468)">
          <g
            id="g21428"
            transform="matrix(.97836 .20691 -.20308 .96028 -8.1586 -363.86)"
          >
            <path
              id="path20390"
              fill="url(#radialGradient3708)"
              d="m422 432c-44.712 0-81 26.592-81 84 0 55.049 48.062 118.53 77.344 123.66-1.0394 0.72957-2.4261 1.0311-3.5938 3.9375-1.8682 4.6503 4.7837 0.99671 4.875 0.0937 0.19427-1.9208 3.3716 1.6031 4.5312 3.625s3.1154 0.82531 3.4375 0.21875c0.32211-0.60654-0.0368-3.7044-0.78125-5.375-0.78224-1.7553-1.2163-1.2879-2.1562-2.3438 29.15-3.84 78.34-68.15 78.34-123.82 0-57.408-36.288-84-81-84z"
            />
            <path
              id="path20392"
              fill="url(#radialGradient3710)"
              d="m422 432c-44.712 0-81 26.592-81 84 0 55.049 48.062 118.53 77.344 123.66-1.0394 0.72957-2.4261 1.0311-3.5938 3.9375-1.8682 4.6503 4.7837 0.99671 4.875 0.0937 0.19427-1.9208 3.3716 1.6031 4.5312 3.625s3.1154 0.82531 3.4375 0.21875c0.32211-0.60654-0.0368-3.7044-0.78125-5.375-0.78224-1.7553-1.2163-1.2879-2.1562-2.3438 29.15-3.84 78.34-68.15 78.34-123.82 0-57.408-36.288-84-81-84z"
            />
            <path
              id="path20394"
              opacity=".5"
              fill="url(#radialGradient3712)"
              d="m422 432c-44.712 0-81 26.592-81 84 0 47.131 35.239 100.42 63.75 118-22.293-24.559-42.656-63.918-42.656-99.75 0-57.408 36.288-84 81-84 16.071 0 31.024 3.464 43.625 10.531-14.79-19.48-38.28-28.78-64.72-28.78zm-7.3438 211.84c-0.78171 2.2084 0.56435 2.2899 2.0625 1.75-0.68855-0.57475-1.3719-1.1415-2.0625-1.75z"
            />
            <path
              id="path20396"
              opacity=".5"
              fill="url(#radialGradient3714)"
              d="m470.72 445.59c14.33 13.828 23.156 34.665 23.156 63 0 55.675-49.185 119.98-78.344 123.81-3.6657 1.9589-3.7735 2.8461-2.9912 4.6014 0.74447 1.6706 6.2508 2.5108 5.9287 3.1173-0.21286 0.40083-1.124 1.0579-2.0625 0.875-0.56053 0.52918-1.1328 1.2907-1.6562 2.5938-1.8682 4.6503 4.7837 0.99671 4.875 0.0937 0.19427-1.9208 3.3716 1.6031 4.5312 3.625s3.1154 0.82531 3.4375 0.21875c0.32211-0.60654-0.0368-3.7044-0.78125-5.375-0.78224-1.7553-1.2163-1.2879-2.1562-2.3438 29.15-3.81 78.34-68.12 78.34-123.79 0-33.944-12.668-57.136-32.281-70.406z"
            />
            <path
              id="path20398"
              opacity=".9"
              fill="url(#radialGradient3716)"
              d="m493.44 493.93c0 32.243-31.53 58.411-70.379 58.411s-70.379-26.168-70.379-58.411 31.53-58.411 70.379-58.411 70.379 26.168 70.379 58.411z"
            />
            <path
              id="path20400"
              fillRule="evenodd"
              fill="url(#linearGradient3718)"
              d="m424.85 640.82s1.0837 0.45153 1.5352 1.6255c0.45154 1.174 1.3546 4.2444 0.99338 4.7863-0.36123 0.54184-0.90307 0.81276-1.3546 0.90306-0.45154 0.0903-0.36123-0.81276-0.0903-1.8964 0.27092-1.0837 0.36123-0.99337 0-2.6189s-0.90307-2.7092-1.0837-2.7995z"
            />
            <path
              id="path20402"
              fillRule="evenodd"
              fill="url(#linearGradient3720)"
              d="m415.55 645.6s2.8898-0.99337 3.251-1.5352c0.36123-0.54184 0.45153-1.2643 0.90307-1.4449 0.45153-0.18062 0.72245-0.81276 0.72245-0.81276l-1.6255-0.0903s0-0.99338-1.4449 0.90307-1.7158 3.0704-1.8061 2.9801z"
            />
          </g>
          <path
            id="path22443"
            fill="url(#linearGradient3722)"
            d="m269.06 338.5c20.237 12.661 6.7262-5.8676-0.0472 10.875-2.3839 14.782-5.4398 29.248-5.455 44.273 2.451 14.183 6.2211 27.839 6.3377 42.377-1.1112 14.044-7.4127 26.772-10.158 40.527-0.87183 15.461-0.45493 30.823-2.0388 46.244-1.55 15.342-3.0638 30.659-3.7238 46.07-0.5981 14.05 0.0194 27.921 2.2682 41.809 1.8023 7.1892 3.2398 14.442 4.4754 21.746h-2.7213c-1.0614-7.2917-2.2188-14.567-3.9131-21.746-3.037-13.852-3.747-27.662-2.5697-41.809 0.58016-15.425 2.2607-30.729 3.8973-46.07 2.0559-15.376 0.53067-30.8 1.5904-46.244 3.186-13.594 8.8574-26.545 10.091-40.527-0.0821-14.586-3.424-28.138-5.9318-42.377-0.1959-15.027 3.5376-29.329 4.5762-44.273 11.112-29.658 14.357 11.279 7.2028-10.875h-3.8801z"
          />
        </g>
      </g>
    </svg>
  )
}

export default Balloons
